<template>
  <div class="account">
    <div class="wrapper">
      <div class="screen">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>VIP订单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="query.keyword" placeholder="请输入订单号/手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-loading="downloadLoading" @click="handleDownload">导出Excel</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="tableData" class="main-table" header-row-class-name="table-header"
        :default-sort="{prop: 'createTime', order: 'descending'}" @sort-change="handleSort">
        <el-table-column label="订单号" prop="orderNo" align="center" width="180"></el-table-column>
        <el-table-column label="用户" prop="user.companyName" align="center" width="120"></el-table-column>
        <el-table-column label="联系电话" prop="user.phone" align="center" width="120"></el-table-column>
        <el-table-column label="VIP类型" prop="vipType" align="center" width="100">
          <template slot-scope="{row}">
            {{ getVipTypeName(row.vipType) }}
          </template>
        </el-table-column>
        <el-table-column label="年限" prop="years" align="center" width="80"></el-table-column>
        <el-table-column label="金额" prop="price" align="center" width="100"></el-table-column>
        <el-table-column label="支付方式" prop="payMethod" align="center" width="100">
          <template slot-scope="{row}">
            {{ row.payMethod === 1 ? '微信' : '管理员充值' }}
          </template>
        </el-table-column>
        <el-table-column label="到期日期" prop="endTime" align="center" width="160"></el-table-column>
        <el-table-column label="开票状态" prop="invoiceStatus" align="center" width="100">
          <template slot-scope="{row}">
            <el-tag :type="row.invoiceStatus === 1 ? 'success' : 'warning'">
              {{ row.invoiceStatus === 0 ? '未申请' : (row.invoiceStatus === 1 ? '已申请' : '已开票') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发票类型" prop="invoiceType" align="center" width="100">
          <template slot-scope="{row}">
            {{ row.invoiceType === 1 ? '全电普票' : '全电专票' }}
          </template>
        </el-table-column>
        <el-table-column label="抬头类型" prop="invoiceTitleType" align="center" width="100">
          <template slot-scope="{row}">
            {{ row.invoiceTitleType === 1 ? '个人' : '企业' }}
          </template>
        </el-table-column>
        <el-table-column label="发票抬头" prop="invoiceTitle" align="center" width="200"></el-table-column>
        <el-table-column label="纳税人识别号" prop="taxpayerId" align="center" width="180"></el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="{row}">
            <el-button
              v-if="row.invoiceStatus == 1"
              type="primary"
              size="small"
              @click="handleUpload(row)">上传发票</el-button>
            <el-button
              v-if="row.invoiceStatus == 2"
              type="primary"
              size="small"
              @click="handleDownload(row)">下载发票</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page.sync="query.page"
        :page-size="query.pagesize"
        @current-change="getList">
      </el-pagination>
    </div>

    <!-- 上传发票弹窗 -->
    <el-dialog title="上传发票" :visible.sync="uploadDialogVisible" width="30%">
      <el-upload
        class="upload-demo"
        :action="uploadUrl"
        :headers="headers"
        :on-success="handleUploadSuccess"
        :before-upload="beforeUpload">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png/pdf文件</div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import { getOrders, getVipTypes, uploadInvoice } from '@/api/vip'

export default {
  data() {
    return {
      headers: {token: localStorage.getItem("token")},
      downloadLoading: false,
      formInline: {},
      tableData: [],
      query: {
        keyword: '',
        page: 1,
        pagesize: 10,
      },
      total: 0,
      vipTypes: [],
      uploadDialogVisible: false,
      uploadUrl: '/api/admin/upload',
      uploadData: {
        orderId: '',
        invoiceUrl: ''
      }
    }
  },
  created() {
    this.getVipTypes()
    this.getList()
  },
  methods: {
    async getVipTypes() {
      const res = await getVipTypes()
      this.vipTypes = res.data
    },
    getVipTypeName(type) {
      const vipType = this.vipTypes.find(item => item.vipType === type)
      return vipType ? vipType.vipName : ''
    },
    getList() {
      getOrders(this.query).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    onSubmit() {
      this.query.page = 1
      this.getList()
    },
    handleSort(e) {
      this.query.sortProp = e.prop
      this.query.sortOrder = e.order
      this.query.page = 1
      this.getList()
    },
    handleUpload(row) {
      this.uploadData.orderId = row.id
      this.uploadData.invoiceUrl = ''
      this.uploadDialogVisible = true
    },
    handleDownload(row) {
      let url = row.invoiceUrl
      // 生成下载链接 
      const a = document.createElement('a')
      a.href = url
      const ext = url.substring(url.lastIndexOf('.'))
      a.download = '发票' + ext
      a.click()
    },
    beforeUpload(file) {
      const isValidType = ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)
      if (!isValidType) {
        this.$message.error('只能上传jpg/png/pdf格式文件！')
        return false
      }
      return true
    },
    handleUploadSuccess(response) {
      if (response.code === 0) {
        this.uploadData.invoiceUrl = response.data.url
        uploadInvoice(this.uploadData).then(res => {
          if (res.code === 0) {
            this.$message.success('上传成功')
            this.uploadDialogVisible = false
            this.getList()
          } else {
            this.$message.error(res.msg || '上传失败')
          }
        })
      } else {
        this.$message.error(response.msg || '上传失败')
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/account.scss';
</style>
